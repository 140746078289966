import { graphql, useStaticQuery } from "gatsby"

export default function useHomeData() {
  const data = useStaticQuery(graphql`
    query getStartData {
      markdownRemark(frontmatter: {url: {eq: "start"}}) {
    id
    frontmatter {
      title
      list {
        item
      }
      content
    }
  }
  }

  `)
  return data.markdownRemark
}
