import React from "react"

const NumberCounter = ({ list }) => (
  <ol className="benefits text-center counters container-sm">
          {
            list.map((item, i) => {
              return <li>
                    <span className="counter">{i + 1}</span>
                    <span><div dangerouslySetInnerHTML={{__html: item.item}} /></span>
                  </li>

            })
          }
  </ol>
)

export default NumberCounter
