import Layout from "../../components/Layout"
import React from "react"
import useStartData from "../../static_queries/useStartData"
import NumberCounter from "../../components/NumberCounter"

export default function StartPage() {
  const { frontmatter } = useStartData();

  return <Layout showCta={false} page="home" title={frontmatter.title} subtitle={frontmatter.subtitle}>
      <div className="container-sm">
      <NumberCounter list={frontmatter.list}/>
      <div style={{color: "#5B6A87"}} className="hero-paragraph section-paragraph text-center" dangerouslySetInnerHTML={{__html: frontmatter.content}} />
        <form name="org-contact-us"
              data-netlify-honeypot="bot-field"
              method="POST"
              action="/success"
              data-netlify="true">
          <input type="hidden" name="form-name" value="org-contact-us"/>
          <input type="hidden" name="bot-field"/>

          <div className="hero-form newsletter-form field field-grouped">
            <div className="control control-expanded">
              <input required="true" className="input" type="email" name="email" placeholder="Email"/>
            </div>
            <div className="control">
              <input required="true" className="input" type="tel" name="phone number" placeholder="Phone Number"/>
            </div>


          </div>
          <div className="field field-grouped mt-8">
            <div className="control control-expanded">
              <input required="true" className="input" type="text" name="your name" placeholder="Your Name"/>
            </div>
            <div className="control">
              <input className="input" type="text" name="organization name" placeholder="Organization Name"/>
            </div>


            <div className="control">
              <select className="input" name="organization type"
                      onChange="showfield(this.options[this.selectedIndex].value)">
                <option value="Organization Type">--Organization Type--</option>
                <option value="Chronic Illness Support Organization">Chronic Illness Support Organization</option>
                <option value="Community Focused Organization">Community Focused Organization</option>
                <option value="Community Health & Wellness">Community Health & Wellness</option>
                <option value="Estate Planners & Financial Advisors">Estate Planners or Financial Advisors</option>
                <option value="Faith Based or Spiritual Community">Faith Based or Spiritual Community</option>
                <option value="Foundation">Foundation, College or University</option>
                <option value="Funeral Planners">Funeral Planners</option>
                <option value="Hospice or Palliative Care Program">Hospice or Palliative Care Program</option>
                <option value="Medical & Healthcare Organization">Medical or Healthcare Organization</option>
                <option value="Nonprofit or Charitable Organization">Nonprofit or Charitable Organization</option>
                <option value="Senior Living or Retirement Community">Senior Living or Retirement Community</option>
                <option value="Small Business or Enterprise">Small Business or Enterprise</option>
                <option value="Other">Other</option>
              </select>
              <div id="div1"></div>
            </div>
          </div>

          <div className="field field-grouped mt-8">
            <div className="control control-expanded">
              <input required="true" className="input" type="text" name="city" placeholder="City"/>
            </div>
            <div className="control">
              <input required="true" className="input" type="text" name="state" placeholder="State"/>
            </div>

            <div className="control">
              <input required="true" className="input" type="text" name="country" placeholder="Country"/>
            </div>

          </div>


          <div className="control mt-8">
            <textarea required="true" className="textarea input" name="how help" rows="5"
                      placeholder="How would you like LivingWisely to help your organization?"></textarea>
          </div>

          <div className="control mt-8">
            <textarea required="true" className="textarea input" name="how find" rows="5"
                      placeholder="How did you find us? May we thank someone for the referral?"></textarea>
          </div>
          <p style={{color: "#5B6A87"}} className="hero-paragraph mt-24">
            <i>Your relationship, trust and time are important to us! Please rest assured that we will never sell or
              share your information. Thank you!
            </i>
          </p>
          <p className="hero-cta mt-16">
            <button type="submit" className="button button-primary button-shadow">Request Meeting</button>
          </p>
        </form>


      </div>
    </Layout>
}
